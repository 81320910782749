import select2 from 'select2';
import "dropzone/dist/dropzone-min";
$( window ).on("load", function() {
    if($('.inputBlock--select').length > 0) {
        $('.inputBlock--select').each(function(){
            if($(this).attr('data-placeholder')) {
                var placeholder = $(this).attr('data-placeholder')
            } else {
                var placeholder = 'Select';
            }
            if($(this).hasClass('srch')) {
                $(this).select2({
                    placeholder: placeholder,
                    dropdownCssClass: "with-srch",
                });
            } else {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    placeholder: placeholder,
                });
            }

        });
    }

    if($('input[name="companys-website"]').length > 0) {
        $(document).on('focus', 'input[name="companys-website"]', function(e){
            if($(this).val() === '') {
                $(this).val('https://');
            }
        });
        $(document).on('keydown', 'input[name="companys-website"]', function(e) {
            var oldvalue = $(this).val();
            var field = this;
            setTimeout(function () {
                if(field.value.indexOf('https://') !== 0) {
                    $(field).val(oldvalue);
                }
            }, 1);
        });
    }

    $(document).on('keypress', 'input[name="name-surname"]', function(e){
        clsAlphaNoOnly(e)
    });

    $(document).on('click', '.application__controlsList--item', function(){
        var thisEl = $(this);
        var thisNum = thisEl.attr('data-this');
        var thisIndicatorBtn = $('.applicationForm__indicatorBtn[data-this="' + thisNum + '"]');
        var thisBlock = $('.applicationForm__block[data-block="' + thisNum + '"]');
        if(!(thisEl.hasClass('active'))) {
            $('.application__controlsList--item').not(thisEl).removeClass('active');
            $('.applicationForm__indicator').not(thisIndicatorBtn).removeClass('active');
            thisIndicatorBtn.addClass('active');
            thisEl.addClass('active');
            if($('.applicationForm__block.active').length > 0) {
                $('.applicationForm__block').removeClass('active');
                thisBlock.slideDown(200);
            } else {
                $('.applicationForm__block').find('.applicationForm__blockWrapper').not(thisBlock.find('.applicationForm__blockWrapper')).slideUp(200, function (){
                    thisBlock.find('.applicationForm__blockWrapper').slideDown(200);
                });
            }
        }
    });

    $(document).on('click', '.applicationForm__indicatorBtn', function(){
        var thisEl = $(this);
        var thisNum = thisEl.attr('data-this');
        var thisControlsBtn = $('.application__controlsList--item[data-this="' + thisNum + '"]')
        var thisBlock = $('.applicationForm__block[data-block="' + thisNum + '"]');
        if(!(thisEl.hasClass('active'))) {
            $('.applicationForm__indicatorBtn').not(thisEl).removeClass('active');
            thisEl.addClass('active');
            $('.application__controlsList--item').not(thisControlsBtn).removeClass('active');
            thisControlsBtn.addClass('active');
            if($('.applicationForm__block.active').length > 0) {
                $('.applicationForm__block').removeClass('active');
                thisBlock.slideDown(200);
            } else {
                $('.applicationForm__block').find('.applicationForm__blockWrapper').not(thisBlock.find('.applicationForm__blockWrapper')).slideUp(200, function (){
                    thisBlock.find('.applicationForm__blockWrapper').slideDown(200);
                });
            }
        }
    });


    $(document).on('click', '.applicationFormNext', function(){
        var thisBtn = $(this);
        var thisBlock = thisBtn.parents('.applicationForm__block');
        var thisBlockNum = parseInt(thisBlock.attr('data-block'));
        var thisBtn = $('.application__controlsList--item[data-this="' + thisBlockNum + '"]');
        var thisIndicatorBtn = $('.applicationForm__indicatorBtn[data-this="' + thisBlockNum + '"]');
        var nextBlock = $('.applicationForm__block[data-block="' + (thisBlockNum + 1 ) + '"]');
        var nextBtn = $('.application__controlsList--item[data-this="' + (thisBlockNum + 1 ) + '"]');
        var nextIndicatorBtn = $('.applicationForm__indicatorBtn[data-this="' + (thisBlockNum + 1 ) + '"]');
        var els = thisBlock.find('.rqrd');

        var checkRqrdForCheck = checkRqrd(els);
        if(checkRqrdForCheck) {
            $('.application__controlsList--item').not(nextBtn).removeClass('active');
            $('.applicationForm__indicatorBtn').not(nextIndicatorBtn).removeClass('active');
            nextBtn.addClass('active');
            nextIndicatorBtn.addClass('active');
            thisBlock.removeClass('invalid');
            thisBlock.addClass('valid');
            thisBtn.removeClass('invalid');
            thisBtn.addClass('valid');
            thisIndicatorBtn.removeClass('invalid');
            thisIndicatorBtn.addClass('valid');
            if($('.applicationForm__block.active').length > 0) {
                $('.applicationForm__block').removeClass('active');
                thisBlock.slideDown(200);
            } else {
                $('.applicationForm__block').find('.applicationForm__blockWrapper').not(nextBlock.find('.applicationForm__blockWrapper')).slideUp(200, function (){
                    nextBlock.find('.applicationForm__blockWrapper').slideDown(200, function(){
                        $([document.documentElement, document.body]).animate({
                            scrollTop: nextBlock.offset().top - 150
                        }, 300);
                    });
                });
            }
        } else {
            $('.application__controlsList--item[data-this="' + thisBlockNum + '"]').addClass('invalid');
            $('.applicationForm__indicatorBtn[data-this="' + thisBlockNum + '"]').addClass('invalid');
            // thisBlock.addClass('invalid');
            thisBlock.addClass('invalid');
            thisBlock.removeClass('valid');
            thisBtn.addClass('invalid');
            thisBtn.removeClass('valid');
            thisIndicatorBtn.addClass('invalid');
            thisIndicatorBtn.removeClass('valid');
        }
    });


    $(document).on('change', 'input[name="company-generate-revenue"]', function(){
        var thisRadio = $(this);
        var thisVal = thisRadio.val();
        var mainInputHolder = thisRadio.parents('.inputHolder');
        var inputPreWrapper = mainInputHolder.find('.inputPreWrapper');
        var inps = mainInputHolder.find('input').not('input[type=radio]');
        if(thisVal === 'Yes') {
            inputPreWrapper.slideDown(200);
            inps.addClass('rqrd');
        } else if(thisVal === 'No') {
            inputPreWrapper.slideUp(200);
            inps.removeClass('rqrd');
            inps.each(function(){
                var t = $(this);
                var t_block = t.parents('.inputBlock');
                var t_message = t_block.find('.inputBlock--message');
                if(t_block.hasClass('invalid')) {
                    t.removeClass('invalid');
                    t_block.removeClass('invalid');
                    t_message.slideUp(200);
                }
            });
        }
    });


    $(document).on('focus', '.rqrd', function (){
        if($(this).hasClass('invalid') || $(this).parent().hasClass('invalid')) {
            $(this).removeClass('invalid');
            $(this).parents('.inputBlock').removeClass('invalid');
            $(this).parents('.inputBlock').find('.inputBlock--message').slideUp(200);
        }
    });

    $(document).on('click', '.select2', function(){
        var thisEl = $(this);
        var thisBlock = thisEl.parents('.inputBlock');
        var thisSelect = thisBlock.find('select');
        var thisMessage = thisBlock.find('.inputBlock--message');
        if(thisBlock.hasClass('invalid')) {
            thisBlock.removeClass('invalid');
            thisSelect.removeClass('invalid');
            thisMessage.slideUp(200);
        }
    });

    $(document).on('focusout', '.rqrd', function (){
        blockValidation($(this).parents('.applicationForm__block'));
    });

    $(document).on('change', 'input[type="checkbox"]', function(){
        if($(this).hasClass('invalid') || $(this).parents('.checkboxInputHolder').hasClass('invalid')) {
            $(this).removeClass('invalid');
            $(this).parents('.checkboxInputHolder').removeClass('invalid');
            $(this).parents('.inputBlock').find('.inputBlock--message').slideUp(200);
        }
        blockValidation($(this).parents('.applicationForm__block'));
    });

    $(document).on('change', 'input[type="radio"]', function(){
        blockValidation($(this).parents('.applicationForm__block'));
    });

    $(document).on('change', 'select', function(){
        blockValidation($(this).parents('.applicationForm__block'));
    });

    $('input[type="text"], input[type="number"], input[type="email"], input[type="url"], textarea').focusout(function(){
        var thisInpt = $(this);
        var message = $(this).val();
        if(/<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE|a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?>|<(video).*?<\/\2>/i.test(message) == true) {
            thisInpt.val(message.replace(/(<([^>]+)>)/ig,""))
        }
    });

    var elements_r_r = document.getElementsByClassName('r_r');

    if (elements_r_r && postmpwbpk.rc_site) {
        grecaptcha.ready(function () {
            grecaptcha_execute(elements_r_r);
        });
        setInterval(function () {
            grecaptcha_execute(elements_r_r)
        }, 240000);
    }


    $(document).on('submit', 'form.applicationForm', function (e){
        e.preventDefault();
        var form = $(this);
        var data = form.serialize();

        $('.loader').removeClass('hide');

        var inps = form.find('.rqrd');
        var checkInps = checkRqrd(inps);

        var valArr = [];
        var returnValue;
        var isEqualTrue = function (currentValue) {
            return currentValue === true
        };
        $('.applicationForm__block').each(function(){
            var thisBlock = $(this)
            var thisBlockNum = parseInt(thisBlock.attr('data-block'));
            var thisBtn = $('.application__controlsList--item[data-this="' + thisBlockNum + '"]');
            var thisIndicatorBtn = $('.applicationForm__indicatorBtn[data-this="' + thisBlockNum + '"]');
            var els = $(this).find('.rqrd');
            var crfc = checkRqrdForCheck(els);
            valArr.push(crfc);
            if(crfc) {
                thisBlock.removeClass('invalid');
                thisBlock.addClass('valid');
                thisBtn.removeClass('invalid');
                thisBtn.addClass('valid');
                thisIndicatorBtn.removeClass('invalid');
                thisIndicatorBtn.addClass('valid');
            } else {
                thisBlock.addClass('invalid');
                thisBlock.removeClass('valid');
                thisBtn.addClass('invalid');
                thisBtn.removeClass('valid');
                thisIndicatorBtn.addClass('invalid');
                thisIndicatorBtn.removeClass('valid');
            }
        });
        returnValue = valArr.every(isEqualTrue);
        if(checkInps && returnValue) {
            if (elements_r_r) {
                grecaptcha_execute(elements_r_r);
            }
            var formData = new FormData();
            formData.append('action', 'handle_application_data');
            formData.append('nonce', postmpwbpk.application_nonce);
            formData.append('data', data);
            if($('input[name="company-presentation[]"]').length > 0) {
                $('input[name="company-presentation[]"]').each(function(){
                    if($(this).val().length > 0) {
                        formData.append('company-presentation[]', $(this)[0].files[0]);
                    }
                });
            }
            if($('input[name="capitalization-table[]"]').length > 0) {
                $('input[name="capitalization-table[]"]').each(function(){
                    if($(this).val().length > 0) {
                        formData.append('capitalization-table[]', $(this)[0].files[0]);
                    }
                });
            }
            $.ajax({
                type: 'POST',
                url: postmpwbpk.ajax_url,
                data: formData,
                dataType: 'json',
                contentType: false,
                processData: false,
                success: function (response) {
                    if(response.data) {
                        var main = $('main.main');
                        var application = main.find('.application');
                        var application_height = application.height() + 'px';
                        main.append(response.data.html);
                        if(typeof gtag !== "undefined") {
                            if ((location.host !== 'tesonet-web.stillnot.live') && (location.host !== 'test.tesonet.com')) {
                                gtag('event', 'Submit', {
                                    'event_category': 'Main Interactions',
                                    'event_label': 'application_form_submit',
                                    'send_to': 'UA-100228991-1'
                                });
                                gtag('event', 'application_form_submit', {
                                    'element_name': 'Submit',
                                    'element_link': location.href,
                                    'element_type': 'submit',
                                    'section_name': 'application',
                                    'send_to': 'G-VSGKRRQ04C'
                                });
                            }
                        }
                        setTimeout(function(){
                            window.location.href = response.data.link;
                        }, 300);
                    }
                    // setTimeout(function(){
                    //     $('.loader').addClass('hide');
                    // }, 800);
                },
                error: function (response) {
                    $('.loader').addClass('hide');
                }
            });
        } else {
            setTimeout(function(){
                $('.loader').addClass('hide');
            }, 300);
        }

    });

    $(document).on('change', '.checkboxInputHolder.alombm input[type="checkbox"]', function(){
        var thisCheckbox = $(this);
        var thisCheckboxHolder = thisCheckbox.parents('.checkboxInputHolder');
        var allCheckedCheckbox = thisCheckboxHolder.find('input[type="checkbox"]:checked');
        var hiddenInp = thisCheckboxHolder.find('input[type="hidden"]');
        var message = thisCheckboxHolder.find('.inputBlock--message');
        if (allCheckedCheckbox.length > 0) {
            hiddenInp.val('true');
            message.slideUp(200);
        } else  {
            hiddenInp.val('false');
        }
    });


    if($('.fileUpload').length > 0) {
        Dropzone.autoDiscover = false;
        var totalSizeLimit = 20*1024*1024; //20MB
        $('.fileUpload').each(function(){
            if ($(this).hasClass('pdf')) {
                var acceptedFiles = '.pdf';
            } else {
                var acceptedFiles = '.csv, .xlsx, .numbers, .pdf';
            }
            var inputName = $(this).attr('data-input');
            var myDropzone = $(this).dropzone({
                uploadMultiple: false,
                maxFilesize: 20,
                url: postmpwbpk.ajax_url + '?action=check_application_files',
                autoQueue: false,
                addRemoveLinks: true,
                acceptedFiles: acceptedFiles,
                sending: function(file, xhr, formData)
                {
                    //... logic before each file send
                },
                accept: function(file, done) {
                    var dz = this;
                    var existSum = getSum();
                    $(file.previewElement).addClass(file.name);
                    if(totalSizeLimit > (file.size + existSum)) {
                        var reader = new FileReader();
                        reader.addEventListener("loadend", function(event) {
                            var inpt = $('<input type="file" class="a-file" name="' + inputName + '[]">');
                            var myFile = new File([event.target.result], file.name, {
                                type: file.type,
                                lastModified: new Date(),
                            });
                            var dataTransfer = new DataTransfer();
                            dataTransfer.items.add(myFile);
                            inpt[0].files = dataTransfer.files;
                            file.status = Dropzone.SUCCESS;
                            dz.emit("success", file);
                            dz.emit("complete", file);
                            $(file.previewElement).append(inpt);
                            if ($(this.element).find('.dz-preview').length > 0) {
                                $(this.element).find('.fileUpload__infoAfter').slideDown(200);
                            } else {
                                $(this.element).find('.fileUpload__infoAfter').slideUp(200);
                            }
                        });
                        reader.readAsArrayBuffer(file);
                    } else {
                        var message = 'You can upload max 20mb';
                        $(file.previewElement).addClass("err");
                        $(file.previewElement).find('.dz-filename span[data-dz-name]').text(message);
                        $(file.previewElement).find('.dz-filename span[data-dz-name]').css('color', '#ff5a5f');
                        $(file.previewElement).find('.dz-details').css('opacity', '1');
                        $(file.previewElement).find('.dz-progress').remove();
                        $(file.previewElement).find('.dz-size').remove();
                    }
                },
                removedfile: function (file) {
                    file.previewElement.remove();
                    if ($(this.element).find('.dz-preview').not(".err").length > 0) {
                        $(this.element).find('.fileUpload__infoAfter').slideDown(200);
                    } else {
                        $(this.element).find('.fileUpload__infoAfter').slideUp(200);
                    }
                },
                success: function (file, response) {

                },
                error: function (file, response) {
                    var type = file.type;
                    var typeSplit = type.split("/");
                    var message = 'Upload failed, wrong file format';
                    $(file.previewElement).addClass(typeSplit[1]);
                    $(file.previewElement).addClass("err");
                    $(file.previewElement).find('.dz-size').text(response);
                    if ($(this.element).find('.dz-preview').not(".err").length > 0) {
                        $(this.element).find('.fileUpload__infoAfter').slideDown(200);
                    } else {
                        $(this.element).find('.fileUpload__infoAfter').slideUp(200);
                    }
                }

            });

            function getSum() {
                var sizeSum = 0;
                if($('input[type="file"].a-file').length > 0) {
                    $('input[type="file"].a-file').each(function (e){
                        sizeSum += $(this)[0].files[0].size;
                    });
                }
                return sizeSum;
            }
            $('.fileUpload').on('change', function (e) {

            });
        });


    }


    function grecaptcha_execute(elements){
        grecaptcha.execute(postmpwbpk.rc_site, { action: 'form' }).then(function (token) {
            Array.from(elements).map(
                (element) => {
                    return element.value=token;
                }
            );
        });
    }


    function blockValidation(blck) {
        var thisBlockNum = parseInt(blck.attr('data-block'));
        var thisControlsList = $('.application__controlsList--item[data-this="' + thisBlockNum + '"]');
        var els = blck.find('.rqrd');

        var valArr = [];
        var returnValue;
        var isEqualTrue = function (currentValue) {
            return currentValue === true
        };
        els.each(function (index) {
            if ($(this).attr('type') === 'text') {
                valArr.push(inpNotEmptyForCheck($(this)));
            } else if ($(this).attr('type') === 'number') {
                valArr.push(validationNumberForCheck($(this)));
            } else if ($(this).attr('type') === 'email') {
                valArr.push(validateEmailForCheck($(this)));
            } else if ($(this).attr('type') === 'url') {
                valArr.push(validUrlForCheck($(this)));
            } else if ($(this).attr('type') === 'file') {
                valArr.push(validationFileInputForCheck($(this)));
            } else if ($(this).is('select')) {
                valArr.push(selectNotEmptyForCheck($(this)));
            } else if ($(this).attr('type') === 'checkbox') {
                valArr.push(ifCheckboxCheckedForCheck($(this)));
            } else if ($(this).is('textarea')) {
                valArr.push(validationTextareaForCheck($(this)));
            } else if ($(this).attr('type') === 'hidden') {
                valArr.push(validationHiddenForCheck($(this)));
            }
        });
        returnValue = valArr.every(isEqualTrue);
        if(blck.hasClass('invalid')) {
            if(returnValue) {
                thisControlsList.removeClass('invalid');
                thisControlsList.addClass('valid');
                blck.removeClass('invalid');
                blck.addClass('valid');
            } else {
                thisControlsList.removeClass('valid');
                thisControlsList.addClass('invalid');
                blck.removeClass('valid');
                blck.addClass('invalid');
            }
        }

    }


    // Check or Required field is valid
    function checkRqrd(els) {
        var valArr = [];
        var returnValue;
        var isEqualTrue = function (currentValue) {
            return currentValue === true
        };
        els.each(function (index) {
            if ($(this).attr('type') === 'text') {
                valArr.push(inpNotEmpty($(this)));
            } else if ($(this).attr('type') === 'number') {
                valArr.push(validationNumber($(this)));
            } else if ($(this).attr('type') === 'email') {
                valArr.push(validateEmail($(this)));
            } else if ($(this).attr('type') === 'url') {
                valArr.push(validUrl($(this)));
            } else if ($(this).attr('type') === 'file') {
                valArr.push(validationFileInput($(this)));
            } else if ($(this).is('select')) {
                valArr.push(selectNotEmpty($(this)));
            } else if ($(this).attr('type') === 'checkbox') {
                valArr.push(ifCheckboxChecked($(this)));
            } else if ($(this).is('textarea')) {
                valArr.push(validationTextarea($(this)));
            } else if ($(this).attr('type') === 'hidden') {
                valArr.push(validationHidden($(this)));
            }
        });
        returnValue = valArr.every(isEqualTrue);
        return returnValue;
    }


    function checkRqrdForCheck(els) {
        var valArr = [];
        var returnValue;
        var isEqualTrue = function (currentValue) {
            return currentValue === true
        };
        els.each(function (index) {
            if ($(this).attr('type') === 'text') {
                valArr.push(inpNotEmptyForCheck($(this)));
            }  else if ($(this).attr('type') === 'number') {
                valArr.push(validationNumberForCheck($(this)));
            } else if ($(this).attr('type') === 'email') {
                valArr.push(validateEmailForCheck($(this)));
            } else if ($(this).attr('type') === 'url') {
                valArr.push(validUrlForCheck($(this)));
            } else if ($(this).attr('type') === 'file') {
                valArr.push(validationFileInputForCheck($(this)));
            } else if ($(this).is('select')) {
                valArr.push(selectNotEmptyForCheck($(this)));
            } else if ($(this).attr('type') === 'checkbox') {
                valArr.push(ifCheckboxCheckedForCheck($(this)));
            } else if ($(this).is('textarea')) {
                valArr.push(validationTextareaForCheck($(this)));
            }
        });
        returnValue = valArr.every(isEqualTrue);
        return returnValue;
    }


    // Input Validation
    function inpNotEmptyForCheck(inp) {
        var inpVal = true;
        if ($(inp).val().trim() === "") {
            inpVal = false;
        } else {
            inpVal = true;
        }
        return inpVal;
    }


    // Input Validation
    function inpNotEmpty(inp) {
        var inpVal = true;
        if ($(inp).val().trim() === "") {
            $(inp).parent().addClass('invalid');
            $(inp).addClass('invalid');
            $(inp).parent().find('.inputBlock--message').slideDown(200);
            inpVal = false;
        } else {
            $(inp).parent().removeClass('invalid');
            $(inp).removeClass('invalid');
            $(inp).parent().find('.inputBlock--message').slideUp(200);
            inpVal = true;
        }
        return inpVal;
    }

    // Email Validation
    function validateEmail(el) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        ;
        var valEmail;
        if (!re.test($(el).val())) {
            $(el).parent().addClass('invalid');
            $(el).addClass('invalid');
            $(el).parent().find('.inputBlock--message').slideDown(200);
            valEmail = false;
        } else {
            $(el).parent().removeClass('invalid');
            $(el).removeClass('invalid');
            $(el).parent().find('.inputBlock--message').slideUp(200);
            valEmail = true;
        }
        return valEmail;
    }

    function validateEmailForCheck(el) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        ;
        var valEmail;
        if (!re.test($(el).val())) {
            valEmail = false;
        } else {
            valEmail = true;
        }
        return valEmail;
    }


    // Check or Checkbox is checked
    function ifCheckboxChecked(element) {
        var returnVal;
        if ($(element).is(':checked')) {
            $(element).parents('.checkboxInputHolder').removeClass('invalid');
            // $(element).removeClass('invalid');
            $(element).parent().find('.inputBlock--message').slideUp(200);
            returnVal = true;
        } else {
            $(element).parents('.checkboxInputHolder').addClass('invalid');
            // $(element).addClass('invalid');
            $(element).parent().find('.inputBlock--message').slideDown(200);
            returnVal = false;
        }
        return returnVal;
    }


    function ifCheckboxCheckedForCheck(element) {
        var returnVal;
        if ($(element).is(':checked')) {
            returnVal = true;
        } else {
            returnVal = false;
        }
        return returnVal;
    }


    // Textarea Validation, Check words length
    function validationTextarea(el) {
        var el = el;
        var string = el.val();
        var returnValue;
        // remove multiple spaces and trailing space
        string = string.replace(/ +(?= )| $/g, '');
        var words = string.split(' ');
        if (string.trim().length > 10) {
            el.removeClass('invalid');
            el.parents('.inputBlock').removeClass('invalid');
            el.parent().find('.inputBlock--message').slideUp(200);
            returnValue = true;
        } else {
            el.addClass('invalid');
            el.parents('.inputBlock').addClass('invalid');
            el.parent().find('.inputBlock--message').slideDown(200);
            returnValue = false;
        }
        return returnValue;
    }


    function validationTextareaForCheck(el) {
        var el = el;
        var string = el.val();
        var returnValue;
        var thisId = el.attr('id');
        var thisBlock = el.parents('.inputBlock');
        // remove multiple spaces and trailing space
        string = string.replace(/ +(?= )| $/g, '');
        var words = string.split(' ');
        if (string.trim().length > 10) {
            returnValue = true;
        } else {
            returnValue = false;
        }
        return returnValue;
    }

    // Validation for Url
    function validUrl(el) {
        var returnValue;
        var thisEl = el;
        // with http:// or https://
        var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        // var regex = /^(?:https?:\/\/|http?:\/\/|s?ftps?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/;
        if (!regex.test(thisEl.val().trim())) {
            // addInvalid(thisEl);
            thisEl.addClass('invalid');
            thisEl.parents('.inputBlock').addClass('invalid');
            thisEl.parent().find('.inputBlock--message').slideDown(200);
            returnValue = false;
        } else {
            thisEl.removeClass('invalid');
            thisEl.parents('.inputBlock').removeClass('invalid');
            thisEl.parent().find('.inputBlock--message').slideUp(200);
            returnValue = true;
        }
        return returnValue;
    }

    function validUrlForCheck(el) {
        var returnValue;
        var thisEl = el;
        // with http:// or https://
        var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        // var regex = /^(?:https?:\/\/|http?:\/\/|s?ftps?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/;
        if (!regex.test(thisEl.val().trim())) {
            returnValue = false;
        } else {
            returnValue = true;
        }
        return returnValue;
    }


    // File Input Validation
    function validationFileInput(el) {
        var el = el;
        var returnVal;
        if (el.val() !== "" || el.attr('value')) {
            el.removeClass('invalid');
            el.parent().removeClass('invalid');
            returnVal = true;
        } else {
            el.addClass('invalid');
            el.parent().addClass('invalid');
            returnVal = false;
        }
        return returnVal;
    }


    function validationFileInputForCheck(el) {
        var el = el;
        var returnVal;
        if (el.val() !== "") {
            returnVal = true;
        } else {
            returnVal = false;
        }
        return returnVal;
    }


    function fileValidation(ctrl, size) {
        var inpVal = true;
        var inputNum = parseInt($(ctrl).attr('data-file-input')) + 1;
        var fileUpload = ctrl;
        var fieldFile = ctrl.files[0];
        if (fieldFile.size > size) {
            fileUpload.parentElement.classList.add("invalid");
            fileUpload.parentElement.classList.add("size");
            inpVal = false;
            return inpVal;
        } else {
            fileUpload.parentElement.classList.remove("invalid");
            fileUpload.parentElement.classList.remove("size");
            inpVal = true;
            return inpVal;
        }
    }


    // Check or Select Value Not Empty
    function selectNotEmpty(el) {
        var returnVal;
        if ($(el).val().trim() === "") {
            $(el).parent().addClass('invalid');
            $(el).addClass('invalid');
            $(el).parent().find('.inputBlock--message').slideDown(200);
            returnVal = false;
        } else {
            $(el).parent().removeClass('invalid');
            $(el).removeClass('invalid');
            $(el).parent().find('.inputBlock--message').slideUp(200);
            returnVal = true;
        }
        return returnVal;
    }


    function selectNotEmptyForCheck(el) {
        var returnVal;
        if ($(el).val().trim() === "") {
            returnVal = false;
        } else {
            returnVal = true;
        }
        return returnVal;
    }


    function validationHidden(el) {
        var returnVal
        if(($(el).val().trim() === "") || ($(el).val() === 'false') || ($(el).val() === false)) {
            $(el).parents('.alombm').addClass('invalid');
            $(el).parents('.inputBlock').find('.inputBlock--message').slideDown(200);
            returnVal = false;
        } else {
            $(el).parents('.alombm').removeClass('invalid');
            $(el).parents('.inputBlock').find('.inputBlock--message').slideUp(200);
            returnVal = true;
        }
        return returnVal;
    }

    function validationHiddenForCheck(el) {
        var returnVal
        if(($(el).val().trim() === "") || ($(el).val() === 'false') || ($(el).val() === false)) {
            returnVal = false;
        } else {
            returnVal = true;
        }
        return returnVal;
    }

    function validationNumber(inp) {
        var inpVal = true;
        if (($(inp).val().trim() === "") && (!isNaN($(inp).val()))) {
            $(inp).parent().addClass('invalid');
            $(inp).addClass('invalid');
            $(inp).parent().find('.inputBlock--message').slideDown(200);
            inpVal = false;
        } else {
            $(inp).parent().removeClass('invalid');
            $(inp).removeClass('invalid');
            $(inp).parent().find('.inputBlock--message').slideUp(200);
            inpVal = true;
        }
        return inpVal;
    }

    function validationNumberForCheck(inp) {
        var inpVal = true;
        if (($(inp).val().trim() === "") && (!isNaN($(inp).val()))) {
            inpVal = false;
        } else {
            inpVal = true;
        }
        return inpVal;
    }

    function clsAlphaNoOnly (e) {  // Accept only alpha numerics, no special characters
        var regex = new RegExp("^[a-zA-Z;/!@#$%^&*()_+ ]+$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }

        e.preventDefault();
        return false;
    }

});